<template>
  <div class="row">
    <div class="col-md-8">
      <edit-profile-form :model="user"></edit-profile-form>
    </div>
    <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./Profile/EditProfileForm";
import UserCard from "./Profile/UserCard";
export default {
  components: {
    EditProfileForm,
    UserCard
  },
  data() {
    return {
      user: {}
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    getUserData() {
      var userData = localStorage.getItem("user_data");
      if (userData) {
        this.user = JSON.parse(userData);
        //console.log(this.user);
      }
    }
  }
};
</script>
<style>
</style>
