<template>
  <card type="user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="javascript:void()" class="cursor-initial">
        <img class="avatar" src="img/anime6.png" alt="..." hidden />
        <span class="avatar tagName">{{tagName}}</span>
        <h5 class="title">{{user.firstName}} {{user.lastName}}</h5>
      </a>
      <p class="card-description">{{user.email}}</p>
    </div>
    <p></p>
    <p class="description text-center mb-5">{{user.roleName }}</p>
  </card>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    tagName() {
      const fn =
        this.user && this.user.firstName
          ? this.user.firstName.substr(0, 1)
          : "";
      const ln =
        this.user && this.user.lastName ? this.user.lastName.substr(0, 1) : "";
      return `${fn}${ln}`;
    }
  },
  methods: {}
};
</script>
<style>
.tagName {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  cursor: initial;
}
.cursor-initial {
  cursor: initial !important;
}
</style>
