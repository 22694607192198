<template>
  <card class="h-100 px-3">
    <h5 slot="header" class="title">Editar Profile</h5>
    <div class="row">
      <div class="col-md-12 px-1">
        <base-input label="Nombre" v-model="model.firstName" placeholder="Nombre" disabled></base-input>
      </div>
      <div class="col-md-12 px-1">
        <base-input label="Apellido" v-model="model.lastName" placeholder="Apellido" disabled></base-input>
      </div>

      <div class="col-md-12 px-1">
        <base-input label="E-mail" v-model="model.email" type="email" placeholder="E-mail" disabled></base-input>
      </div>
      <div class="col-md-12 px-1" hidden>
        <base-input label="Username" placeholder="Username" v-model="model.userName" disabled></base-input>
      </div>

      <div class="col-md-12 px-1" v-if="model.companyName">
        <base-input label="Empresa" placeholder="Empresa" v-model="model.companyName" disabled></base-input>
      </div>
    </div>
    <base-button slot="footer" type="primary" fill hidden>Guadar</base-button>
  </card>
</template>
<script>
export default {
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>
<style>
</style>
